<template>
	<div>
		<pui-datatable
			v-if="!masterDetail"
			:modelName="modelName"
			:modelColumnDefs="modelColumnDefs"
			:showCreateBtn="false"
			:showDeleteBtn="false"
			:readOnly="readOnly"
			:actions="actions"
		></pui-datatable>
		<pui-datatable
			v-else
			:modelName="modelName"
			:modelColumnDefs="modelColumnDefs"
			:externalFilter="externalFilter"
			:masterDetail="masterDetail"
			:parentModelName="parentModelName"
			:modalDialog="modalDialog"
			:readOnly="readOnly"
			:showCreateBtn="showCreateBtn"
			:showDeleteBtn="showDeleteBtn"
			:actions="actions"
		></pui-datatable>
		<resallocationmodals :modelName="modelName"></resallocationmodals>
	</div>
</template>

<script>
import PuiGridDetailMixin from 'pui9-mixins/PuiGridDetailMixin';
import ResourceallocationActions from './ResourceallocationActions.js';
import ResourceallocationModals from './ResourceallocationModals';

export default {
	name: 'resourceallocationgrid',
	components: { resallocationmodals: ResourceallocationModals },
	mixins: [PuiGridDetailMixin],
	data() {
		return {
			modelName: 'resourceallocation',
			actions: ResourceallocationActions.gridactions,
			modelColumnDefs: {
				resourceallocationstatusname: {
					render: (data, type, row) => {
						if (row.statusoutlinecolor && row.statuscolor) {
							return `<label style="color: white; background-color: ${row.statuscolor}; border: 2px solid ${row.statusoutlinecolor}; font-weight: bold; padding: 3px; border-radius: 10px">${data}</label>`;
						} else {
							return data;
						}
					}
				},
				isoperation: {
					render: (data, type, row) => {
						return row.isoperation ? `<i class="fas fa-check state-check"></i>` : '';
					}
				},
				rented: {
					render: (data, type, row) => {
						return row.rented ? `<i class="fas fa-check state-check"></i>` : '';
					}
				},
				parttimejob: {
					render: (data, type, row) => {
						return row.parttimejob ? `<i class="fas fa-check state-check"></i>` : '';
					}
				}
			}
		};
	}
};
</script>
